import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import { OutboundLink } from 'gatsby-plugin-gtag';
import YoutubeVideoUser from 'components/Motion_Detection/INSTAR_Cloud/Create_User_Account/YoutubeVideoCardCloud';
import YoutubeVideoMDA from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/YoutubeVideoCard';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud - Create a User Account",
  "path": "/Motion_Detection/INSTAR_Cloud/Create_User_Account/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Cloud Storage for Motion Detection Recordings",
  "image": "./MD_SearchThumb_Cloud_Create.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Create.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR Cloud - Create a User Account' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR Cloud Storage for Motion Detection Recordings' image='/images/Search/MD_SearchThumb_Cloud_Create.png' twitter='/images/Search/MD_SearchThumb_Cloud_Create.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Motion_Detection/INSTAR_Cloud/Create_User_Account/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Create_User_Account/' crumbLabel="User Account" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <h3 {...{
      "id": "create-a-user-account",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-user-account",
        "aria-label": "create a user account permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create a User Account`}</h3>
    <YoutubeVideoUser mdxType="YoutubeVideoUser" />
    <h2 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h2>
    <p>{`In order to log in to INSTAR Cloud, you must first register with your email address and a password you have chosen. Go to `}<OutboundLink href="https://cloud.instar.de" mdxType="OutboundLink">{`cloud.instar.de`}</OutboundLink>{` and select `}<em parentName="p">{`Register now free of charge`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/38abd/Cloud_Create_User01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABlUlEQVQ4y41T207CQBDtp/MbPuonGBNfBR9Q4ptIgzVISK9IYVvb3bbb7TEzUC1QjZOcdHane+bszKw1e3mC8+7AC1ysP9ZYLBZYrVYIggBhGH4jiiL4vg/XdXm9jiIsVh6m9hyz1zfY8zkcx4H18DjB1dUllJKo6xpaaxhj0DQNg/w+UCwpNDafEkIW2OUKqihhAfuDp0Z7uq6P9mqtOWkb7zOrS9CiXQe+h0QIZFJBJCl2SQop1RmZ6Zy1+oj4J2MQxjuEmxgyiVFmArVKYXTFcSpNURT87Qqy+q56cHDrVxh6OXB3AdwMYK4HgDvF0gtxPxpiPB5zI7bb7fmVTwm5hqaB1jUaGDTPEzRxyDHX9TAajWDbNpRS3HVS2qvwvOCHBIVi1WRCCCyXS+R5jizLmLTt/p8K9zj4TP0Ty/M9kZSSa9mOUq/CDjuj6SQgq6qKVcZxzA+AfBqnoy6fjk4f2u4TIdWMFNK1CaT0SOFvg3pqREhqCGVZMhHVkUi5y22G/yhsnyIpJDJSSPVL0xQ7IZj8C6pF8yuyEysdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb6675a1a2b97354b971d490c2ccd2ca/e4706/Cloud_Create_User01.avif 230w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/d1af7/Cloud_Create_User01.avif 460w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/7f308/Cloud_Create_User01.avif 920w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/e1c99/Cloud_Create_User01.avif 1380w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/76ea5/Cloud_Create_User01.avif 1840w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/80be4/Cloud_Create_User01.avif 2766w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb6675a1a2b97354b971d490c2ccd2ca/a0b58/Cloud_Create_User01.webp 230w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/bc10c/Cloud_Create_User01.webp 460w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/966d8/Cloud_Create_User01.webp 920w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/445df/Cloud_Create_User01.webp 1380w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/78de1/Cloud_Create_User01.webp 1840w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/1ccea/Cloud_Create_User01.webp 2766w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb6675a1a2b97354b971d490c2ccd2ca/81c8e/Cloud_Create_User01.png 230w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/08a84/Cloud_Create_User01.png 460w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/c0255/Cloud_Create_User01.png 920w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/b1001/Cloud_Create_User01.png 1380w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/161ec/Cloud_Create_User01.png 1840w", "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/38abd/Cloud_Create_User01.png 2766w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb6675a1a2b97354b971d490c2ccd2ca/c0255/Cloud_Create_User01.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h2>
    <p>{`Enter your email address and your desired password, and then click `}<strong parentName="p">{`Register now for free`}</strong>{`. You will then receive an email from us in which you have to confirm the registration again. After you have confirmed the registration, you can then log on to the INSTAR Cloud with your account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9ed5355b94fe817f73085ee38d6babc1/00fb1/Cloud_Create_User02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABgUlEQVQ4y41TTW+CQBTk//+M9tJD/0FveqBNemxAwVJRBNSoCMguCkwzSxapQdOXDA/e5g3zPtawrE9MvqcIwiXidQzP8+B5PwjDEHEcY71ed4iiCEEQqPhms4G/WOLLnsByZrCnU7juDIb5/oHn5ydIKVDVFS6XC6qqQtM0Heq67lBVrWc8k2fs8gJJIXE4CciyhAFAHd4aY+fzWZH3YyS7l0Mz9EFfEUP0ruvCsixV7na7xWq1wm6360i11b1co0/U/zOTsuMB+TGBLHIIUUBKqVpCo+9/61xjqFTtw0OGxT5DlApI1bu2fN/3MR6PYZomHMfBfr+/lvyI8C0q8TIXePUFYsEy2zOWPhqNYNu2UsmpU+mgQiY1g02/tiVJEsznc+R5riCE6LZgQKF+DA9Kx0+nkyIqira3epUeljwEGteJKjlxLjvf9e4atxN+BD19EhJUSKUElf5ReG9Rb629LZVCWZaKiH0kqZqy/sN/FOqbQnUko0L2L01TVTbJfwGgE/QnF6HAKQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ed5355b94fe817f73085ee38d6babc1/e4706/Cloud_Create_User02.avif 230w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/d1af7/Cloud_Create_User02.avif 460w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/7f308/Cloud_Create_User02.avif 920w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/e1c99/Cloud_Create_User02.avif 1380w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/76ea5/Cloud_Create_User02.avif 1840w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/e1342/Cloud_Create_User02.avif 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9ed5355b94fe817f73085ee38d6babc1/a0b58/Cloud_Create_User02.webp 230w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/bc10c/Cloud_Create_User02.webp 460w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/966d8/Cloud_Create_User02.webp 920w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/445df/Cloud_Create_User02.webp 1380w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/78de1/Cloud_Create_User02.webp 1840w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/e783c/Cloud_Create_User02.webp 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ed5355b94fe817f73085ee38d6babc1/81c8e/Cloud_Create_User02.png 230w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/08a84/Cloud_Create_User02.png 460w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/c0255/Cloud_Create_User02.png 920w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/b1001/Cloud_Create_User02.png 1380w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/161ec/Cloud_Create_User02.png 1840w", "/en/static/9ed5355b94fe817f73085ee38d6babc1/00fb1/Cloud_Create_User02.png 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9ed5355b94fe817f73085ee38d6babc1/c0255/Cloud_Create_User02.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h2>
    <p>{`After logging in, you will usually be able to see all the new alarms. However, if you have not yet created a camera for a new registration, you will be first redirected to the menu item `}<strong parentName="p">{`Cameras`}</strong>{`. There you now have the possibility to add a new camera to your cloud account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51d42bdfab91195d422b5db45152c133/38abd/Cloud_Create_User03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB3ElEQVQ4y52TSW/TQBiG5y9w4efAhdKIC3BD/BxunFlKOSJxRoI2TURyZRGCG20TsB1v4xk3W9ukNvHyoHG6hKSVAEuPvpnX77z6NIvY2/uK5VqEUUAcayzLwrYsZBiiogilVIVWilBKXD8glBFSaTzPY3d/n++dn+x3OnS6PxDt1ns2N57hew5R6CMDr6rLKBngBz7dno/t+dh+gOu52I7NrtWjazt0nR5CDsb4eoBjOXhegOt62JZDJE1nek6kqm61iom1RqsLYh1zoOOqxkohkm+PKeo3mdXXyeq3yeprzLZvUTTWKZo1ysYatO5C+z7ljtFrK+TNO5TbN0i/PELMGvfgiYCXAjaX2BDMXl1j0njApPmQ/PV1eH7678UCZv5UMNupIZLwM4Xzhrz3boWi95bcb5FFH8iijxRhm9xoK96tKiMNPyGmSc6vEtLiT4yW5DA6njE8PKE/mtAfJ5V2lX+aFojJ8RFpMr2U5GRCrM1hSCIZoiNZaVf5p5MjRJIkFEVBnufnZFl2Pi7LkpKLb9G3iMlI0wQx6MeMhwNGp5ix0YaD/lwzdZEF7zLD/gEi9F2WMZf7Mv1vEGcv4X8DzhqY53iISAbVhh+OR4xHw3/GrDMHF/helfMbIOSnm2C4tUwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51d42bdfab91195d422b5db45152c133/e4706/Cloud_Create_User03.avif 230w", "/en/static/51d42bdfab91195d422b5db45152c133/d1af7/Cloud_Create_User03.avif 460w", "/en/static/51d42bdfab91195d422b5db45152c133/7f308/Cloud_Create_User03.avif 920w", "/en/static/51d42bdfab91195d422b5db45152c133/e1c99/Cloud_Create_User03.avif 1380w", "/en/static/51d42bdfab91195d422b5db45152c133/76ea5/Cloud_Create_User03.avif 1840w", "/en/static/51d42bdfab91195d422b5db45152c133/80be4/Cloud_Create_User03.avif 2766w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51d42bdfab91195d422b5db45152c133/a0b58/Cloud_Create_User03.webp 230w", "/en/static/51d42bdfab91195d422b5db45152c133/bc10c/Cloud_Create_User03.webp 460w", "/en/static/51d42bdfab91195d422b5db45152c133/966d8/Cloud_Create_User03.webp 920w", "/en/static/51d42bdfab91195d422b5db45152c133/445df/Cloud_Create_User03.webp 1380w", "/en/static/51d42bdfab91195d422b5db45152c133/78de1/Cloud_Create_User03.webp 1840w", "/en/static/51d42bdfab91195d422b5db45152c133/1ccea/Cloud_Create_User03.webp 2766w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51d42bdfab91195d422b5db45152c133/81c8e/Cloud_Create_User03.png 230w", "/en/static/51d42bdfab91195d422b5db45152c133/08a84/Cloud_Create_User03.png 460w", "/en/static/51d42bdfab91195d422b5db45152c133/c0255/Cloud_Create_User03.png 920w", "/en/static/51d42bdfab91195d422b5db45152c133/b1001/Cloud_Create_User03.png 1380w", "/en/static/51d42bdfab91195d422b5db45152c133/161ec/Cloud_Create_User03.png 1840w", "/en/static/51d42bdfab91195d422b5db45152c133/38abd/Cloud_Create_User03.png 2766w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51d42bdfab91195d422b5db45152c133/c0255/Cloud_Create_User03.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h2>
    <p>{`When you add a new camera, you must first assign a name to this camera. In our example, we give the camera the name `}<strong parentName="p">{`Office DE`}</strong>{`. In addition, you must select whether your camera is an HD camera or a VGA camera. To create the camera, please click on `}<strong parentName="p">{`Create Camera`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb35b242d32b74fef434cef1fe8c4b48/00fb1/Cloud_Create_User04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACTUlEQVQ4y62SS08TYRSGR36JMZoYXBh/hJqQAOoChIor0N6h17SdzkyH21TKxhBXUnsJdgH8Bt2gJiRuVAo20oZKKdKWFQHFto/5Rk1U3Hj5kifnLM775CTfkfL5lxTLJcrbW+xUdygUCrwrvGVva5NauUj9fYl9wXaJ2tYmu8UC9XKRnUqFYqnE6zdrvMpvsJbPs76+gWSz2RkYuImiKqiahq6pBGOTDCTSDM5m6U9kuH4vw42ZDL3xDF1Ghm4jjS82jaoohCMygYhCKCITkqNIo74gLo8Ph8OJyz2G027HLk8QXa2hrO7hf/GB4ZUGIysNhlfqjDxrYH3ewK0ZjLndeLw+PB4vHo+oHqTY3SsYlnNMWc4zPdSJ3nea+XAvH/f3OKjvwvEh0AQ+A8df+9YnkuFrjPefwbh9wcwZlrPody4jTVo6me2SSHSfYranA+OqRNJ1iWptn0q1ysHhEc02HDdbNFstzNduknRcNGcTPR3MiGyXhHBJincEfXSQ2NgtdM8QiqOPB/EQ7TYnntjzSfWIp5UD5owQirPPzOgiOzqIcEmRqEpUmyCqjZtVVseZmIqTyz0ml8uRyWRIp9Ok0ykePkoRuj+PPJdEn4ybsz9mhUuKRMIoUZnoN0QvyxECgQB+vx+Xy2XidDpxu1wooaCJmPk1J1ySLMuoqnoCTdOIxWLouv4T4rRMfpMRLklsEgwG/wvCJfl8PrxeL6L+C98dkl0cst2O1Wr9a2w2G989kvjFbDbL0tLSH7O4uMjy8jILCwukUinT8wVNY7QS6a5+GwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb35b242d32b74fef434cef1fe8c4b48/e4706/Cloud_Create_User04.avif 230w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/d1af7/Cloud_Create_User04.avif 460w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/7f308/Cloud_Create_User04.avif 920w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/e1c99/Cloud_Create_User04.avif 1380w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/76ea5/Cloud_Create_User04.avif 1840w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/e1342/Cloud_Create_User04.avif 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb35b242d32b74fef434cef1fe8c4b48/a0b58/Cloud_Create_User04.webp 230w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/bc10c/Cloud_Create_User04.webp 460w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/966d8/Cloud_Create_User04.webp 920w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/445df/Cloud_Create_User04.webp 1380w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/78de1/Cloud_Create_User04.webp 1840w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/e783c/Cloud_Create_User04.webp 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb35b242d32b74fef434cef1fe8c4b48/81c8e/Cloud_Create_User04.png 230w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/08a84/Cloud_Create_User04.png 460w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/c0255/Cloud_Create_User04.png 920w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/b1001/Cloud_Create_User04.png 1380w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/161ec/Cloud_Create_User04.png 1840w", "/en/static/bb35b242d32b74fef434cef1fe8c4b48/00fb1/Cloud_Create_User04.png 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb35b242d32b74fef434cef1fe8c4b48/c0255/Cloud_Create_User04.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5`}</h2>
    <p>{`The camera is now created and you will get all necessary access data to this camera. In the overview, you receive the following information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera name`}</strong>{`: This is the name you have given to the camera and can be changed at any time using `}<strong parentName="li">{`Edit`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Camera type`}</strong>{`: The type of camera, depending on whether you have selected a VGA or HD camera`}</li>
      <li parentName="ul"><strong parentName="li">{`Recordings`}</strong>{`: The number of alarm videos that exist for this camera.`}</li>
      <li parentName="ul"><strong parentName="li">{`Last activity`}</strong>{`: The last activity the camera has reported / recorded an alarm.`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera set`}</strong>{`: If camera is assigned to one or more sets. You can find more information in the chapter `}<strong parentName="li">{`Creating Sets`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cloud users`}</strong>{`:The user name for this camera you must store in the web interface of your camera. `}<em parentName="li">{`ATTENTION`}</em>{`: Please make sure you have installed the latest firmware on your camera so you can see the menu item "Cloud" in the web interface.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cloud password`}</strong>{`:The password that must be stored in the web interface of your camera so that the camera can log on to the cloud.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/00fb1/Cloud_Create_User05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACW0lEQVQ4y42Tu2pUURSG90uIz5LKRBFEULSU2ARx0MJILCwsRB9BEEPSCWMhFiMkmUxiimAhoinj5DKZy7mfuZyZc+Z+rp+cnUkYEgsXfPz/+vdmsXaxRbG4R6l2gmHq1Bt1yuUTKuUypmFgWRa2fYaNYZrUVA3DtDDtOoqqUjw4ZP+oxMHhEYfHJcTO9harKx/Q1BqWoUlsU5dYKcappr2maxwrGhVNp6IbKJpKtValWFEoVauUqgrCanvo9RbVchlN1VBqCpVyBcu0qNu23My2TEnaN6aQfb1Oc0LDthCj32+J1mYI1ueINuYI12cJ1q4R568Tb94gyc/CtzuS1KfZZW6SrM0w/vUGMd64RfxOEC4L4mUBKwJWJ7osCLJX6G/N09+eJ/p0VWbybJr0/ntBsHkbgbsPzi4M96D9nVjdJFLyUmM1T6Du4Bs/CK2fJOauzBKtQKIXTlX6LdA28O09xMfsZ569eMXSy9d8+VrA7UeMIvAGMZ1+RJBAEHNeqU0m/qIGEYiH8w+4f+8umcePePokg9txGPR79LwOzYYlfRj4JHEkIYngzE80iUOZB+MR4vniIkIIydLSEsPhkK7n0esPOSpVaRg6wWgot0iS5Bwm/bQGvo/IZrNkMhkWFhbI5XLEcUR/MGLUbeKaBxTrHo3eWD5seuBF0vL9MYILFUUhUWq8MpFWIKf7/PFi/qfCMER0nBZup017ol3Ppet16PYGdAchycBj3HPxZP5vel2Pfq+L57YRhq5h6hqdtkM6XNJOtUnHadByWjjn2WXctiN/kabU5Df9C9VxmN+ug3cPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/e4706/Cloud_Create_User05.avif 230w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/d1af7/Cloud_Create_User05.avif 460w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/7f308/Cloud_Create_User05.avif 920w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/e1c99/Cloud_Create_User05.avif 1380w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/76ea5/Cloud_Create_User05.avif 1840w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/e1342/Cloud_Create_User05.avif 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/a0b58/Cloud_Create_User05.webp 230w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/bc10c/Cloud_Create_User05.webp 460w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/966d8/Cloud_Create_User05.webp 920w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/445df/Cloud_Create_User05.webp 1380w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/78de1/Cloud_Create_User05.webp 1840w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/e783c/Cloud_Create_User05.webp 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/81c8e/Cloud_Create_User05.png 230w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/08a84/Cloud_Create_User05.png 460w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/c0255/Cloud_Create_User05.png 920w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/b1001/Cloud_Create_User05.png 1380w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/161ec/Cloud_Create_User05.png 1840w", "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/00fb1/Cloud_Create_User05.png 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9cb23b4b96c063cd245cca5eb1a3e98d/c0255/Cloud_Create_User05.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-6",
        "aria-label": "step 6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 6`}</h2>
    <p>{`Now open the web interface of your IP camera. Make sure you have the latest firmware installed on your camera. If you have installed the latest firmware, you will see a new menu item `}<strong parentName="p">{`Cloud`}</strong>{` on the left side. Please select it and go to the `}<strong parentName="p">{`Settings`}</strong>{` sub-item. In the menu that opens, you can select the `}<strong parentName="p">{`Enable Cloud Transfer`}</strong>{` option so that the camera will now transfer the alarm video to the cloud. In addition, you must now enter your user name and password, which are displayed in your cloud account. Make sure you copy it, and do not inadvertently copy / paste a space. Last but not least `}<strong parentName="p">{`Confirm`}</strong>{` please make the settings so that they can be stored in the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/39968765ffe340cbaacb5c090dfa5946/35d5c/Cloud_Create_User06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC8klEQVQ4yzWPS28bZRiF/Q+gTZvYju3x3K+esZ20dUo9di52motnPLbTFhaoYlNaqbEAATuWSKCiCkFRywaJDb8GJNttidMGpfyRB83nsDg6+t7v1fOek3n98k9OTue8OT3l/Pwd52/nnL95xb9nJ5ydTDk7mQn/Zz7j7d8T5i//uphPmb+eMpnOmMxeMZ1MmM1mZMKwTfNWk1bYptUMiT/+kuH4GdGnT4gePiV69CO9R0/pPfxBvA8efM/Bg+8YjX9i9+6YJB7SaIQEfo21tXUyhqZj6iaWYaPLGp07n3Pvq98ZffaC/vFzouMXxOPn9B7/THT8THh//Av3v/mDzeQxnuFhqQ5KUUEtymQ8x6PiVgi8KhWnyk7Y5l7SZxQdEu91iW53hO9tt+hu3qLTusnuZsigd8D2ByFGSaduV2n416jbPhnf9Qm8AN8LcEyXrfYOw9Fdojjh8DBaqBfR7d6m091lp7NLs7VFM9wSu3WnRs3yqRoepqynwADfTdMF2KZHu7XJoB8vlAxJ+gPiuC98MBiRJEfE0UBoGI0Y7g2puzUMVcfSjIvKTgXfqWKbPvvbbX79IuK3rxM++XBAMjjiaHRHAKP+kPv9EU8OB3y7H/NRPGJ/+wBV1vBrdeSyQsaxHVzbFQk9O6Ae1GltNNhptdi4sUG9vs762jrVao1KJeD62nXCxk02rt3AMiwBKRZKLL2/RC67SsYyLRbQCp5dwTJtFEVHk21Wc5JYkqQyuWyO5SvL5LJ5CiWJKytZli5dppBbJbu8wuX3LpG7mk8Tuvwv23RID6SXdcVAlXU01cAUSVRKRQmpJKEqKvlcntxKFqlYppAvkL26TClfWgBtyxZK06VAQzcxjXTm4rkV4Zqqi3qqquG6HqZpIcuK2NO09E9GVbRF5VS2aV+AHZFI10wBMfTFAVlWxVtTNUzDxLYdylIZXTVE4nK5LHwBtGwcy8WxHFE9BSiyIhIosnohRRxNYZK0qJ5C0mSpUni68x9RVM37pjIXfQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39968765ffe340cbaacb5c090dfa5946/e4706/Cloud_Create_User06.avif 230w", "/en/static/39968765ffe340cbaacb5c090dfa5946/d1af7/Cloud_Create_User06.avif 460w", "/en/static/39968765ffe340cbaacb5c090dfa5946/7f308/Cloud_Create_User06.avif 920w", "/en/static/39968765ffe340cbaacb5c090dfa5946/e1c99/Cloud_Create_User06.avif 1380w", "/en/static/39968765ffe340cbaacb5c090dfa5946/76ea5/Cloud_Create_User06.avif 1840w", "/en/static/39968765ffe340cbaacb5c090dfa5946/50d77/Cloud_Create_User06.avif 2760w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/39968765ffe340cbaacb5c090dfa5946/a0b58/Cloud_Create_User06.webp 230w", "/en/static/39968765ffe340cbaacb5c090dfa5946/bc10c/Cloud_Create_User06.webp 460w", "/en/static/39968765ffe340cbaacb5c090dfa5946/966d8/Cloud_Create_User06.webp 920w", "/en/static/39968765ffe340cbaacb5c090dfa5946/445df/Cloud_Create_User06.webp 1380w", "/en/static/39968765ffe340cbaacb5c090dfa5946/78de1/Cloud_Create_User06.webp 1840w", "/en/static/39968765ffe340cbaacb5c090dfa5946/884fd/Cloud_Create_User06.webp 2760w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39968765ffe340cbaacb5c090dfa5946/81c8e/Cloud_Create_User06.png 230w", "/en/static/39968765ffe340cbaacb5c090dfa5946/08a84/Cloud_Create_User06.png 460w", "/en/static/39968765ffe340cbaacb5c090dfa5946/c0255/Cloud_Create_User06.png 920w", "/en/static/39968765ffe340cbaacb5c090dfa5946/b1001/Cloud_Create_User06.png 1380w", "/en/static/39968765ffe340cbaacb5c090dfa5946/161ec/Cloud_Create_User06.png 1840w", "/en/static/39968765ffe340cbaacb5c090dfa5946/35d5c/Cloud_Create_User06.png 2760w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/39968765ffe340cbaacb5c090dfa5946/c0255/Cloud_Create_User06.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-7",
        "aria-label": "step 7 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 7`}</h2>
    <p>{`In order for an alarm to be triggered during a movement, movement detection must, of course, be activated. You can do this using `}<strong parentName="p">{`Alarm Areas`}</strong>{`, if you are using the software-sided motion detection. If you use a PIR sensor, please activate it under `}<strong parentName="p">{`Alarm actions`}</strong>{`. After activating motion detection (in our example, we monitor the door and have selected it accordingly), please confirm the settings in the lower right corner.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/790742c38bda205940c2d836edab1202/00fb1/Cloud_Create_User07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAADfUlEQVQ4yz3RW0+bBQDG8X6IJcZlhA20jlOhnNoCPb19aenxPfTwtuXYIrXdBpuCUHBjjqGgMsemsg1QGBshG8FD4oVBo/fqDMxM5igIi/HWj/A37MKL383/6kke3ZPHP7Pzx+/s7jzhYG+X5/sF/j7c55/nBxzu7b5w1A/3C/xVeEbhz6fsFwoc7O/x7OkOW789Ynt7m61Hv/B4ewud3HcR7dIq4dFlImP3iI6tEs7fR82voObvExlbIzK2ijq6gjp6j/DwAlp+nvjoItGRRcJDS8SGV4gNL+PrGEQnCH4yUhfptjhpbztd7iS9vm4ywRSZYJq0r5uutnbeCPVwTkmjKnma2i7j8F3BH76GlBhBSpxB7hjA5gqhk0wmpkqKuXL8JSaKipg4Ucz1sgpuVdcxV2XkZoWBqZJS3i0q4k51BdnmMRqMC+iLJzDoP0L2pVGCHkKSisPqQCdbrMwYapnUl5M/Xsx0mYHZmgY+q2/ihtHEHbONL1pczBpNTOpfIVZ7HrdjHa/1LpWnP8Dr7UFRQkiRJDa7C12w0cK03sBkaRnvvVrBzVozc/UWbje2MG+xs+bysy4G2fAobLi8JOsHqa65jeb7kmTgW1Qpi6qGkFQNu8OFLtBoZuq1SiZKy/ikroVbZhuLVifLTg/LDjcbXoUfYil+SmT4tSfH9dQqmrJJm3OZiG+DVOcIWjxKOJrEZrWjk01NXDtVzmxJDXdrBJaq7Txs9rJh87NktLJSa+drp8Q3gspmq8zn2e+4NPgv/d3bqP7vSXW/QyymICkaLVYBncvexOuBKnJqPW8n7WSlWnr9lQy125nMhbjQ2UJaraHDV4YmnmI49yYPFn/k4cIm8zPrZPv6kSQJNRLH1uJAZwo0oZ+pouLTauoWzBS/X8qx8Zc5Oa2n5OMySm6Uo5+r5sR0CceuniT74WkeLDTw1ZqH1UULA2djKHIMWY1iPVrY7LZh6XdiHrDiznsIXgxgOmfCfMZEQ64Bc7+FxrMmDGkDxmw9vW8JjI94GB4SuTqukEklCIRkgtEENpsTneiwk9MUOiUJyasg+2TCgQgxSSMqxdCUODFZQw2GiUpRlFAEWdGQpQh+uR0l3kcipJIKhhCOTmm1WbiccpGJiLhED+5WN6IoviC4XLhE8X9+nx9fmxdBEBAcNsRQEjFxgXbBy3mrA3uDif8AeKsuagyN124AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/790742c38bda205940c2d836edab1202/e4706/Cloud_Create_User07.avif 230w", "/en/static/790742c38bda205940c2d836edab1202/d1af7/Cloud_Create_User07.avif 460w", "/en/static/790742c38bda205940c2d836edab1202/7f308/Cloud_Create_User07.avif 920w", "/en/static/790742c38bda205940c2d836edab1202/e1c99/Cloud_Create_User07.avif 1380w", "/en/static/790742c38bda205940c2d836edab1202/76ea5/Cloud_Create_User07.avif 1840w", "/en/static/790742c38bda205940c2d836edab1202/e1342/Cloud_Create_User07.avif 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/790742c38bda205940c2d836edab1202/a0b58/Cloud_Create_User07.webp 230w", "/en/static/790742c38bda205940c2d836edab1202/bc10c/Cloud_Create_User07.webp 460w", "/en/static/790742c38bda205940c2d836edab1202/966d8/Cloud_Create_User07.webp 920w", "/en/static/790742c38bda205940c2d836edab1202/445df/Cloud_Create_User07.webp 1380w", "/en/static/790742c38bda205940c2d836edab1202/78de1/Cloud_Create_User07.webp 1840w", "/en/static/790742c38bda205940c2d836edab1202/e783c/Cloud_Create_User07.webp 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/790742c38bda205940c2d836edab1202/81c8e/Cloud_Create_User07.png 230w", "/en/static/790742c38bda205940c2d836edab1202/08a84/Cloud_Create_User07.png 460w", "/en/static/790742c38bda205940c2d836edab1202/c0255/Cloud_Create_User07.png 920w", "/en/static/790742c38bda205940c2d836edab1202/b1001/Cloud_Create_User07.png 1380w", "/en/static/790742c38bda205940c2d836edab1202/161ec/Cloud_Create_User07.png 1840w", "/en/static/790742c38bda205940c2d836edab1202/00fb1/Cloud_Create_User07.png 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/790742c38bda205940c2d836edab1202/c0255/Cloud_Create_User07.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoMDA mdxType="YoutubeVideoMDA" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "step-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-8",
        "aria-label": "step 8 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 8`}</h2>
    <p>{`If the camera triggers alarms, you will find this short time later in your cloud account under `}<strong parentName="p">{`Alarms`}</strong>{`. If you have added multiple cameras in the cloud, you can select the cameras on the left side to see "only" the shots of the selected camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf1b4b7e36746fc01de6465370a1cf30/00fb1/Cloud_Create_User08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB0UlEQVQ4y5WTSW7bQBAA54GGf+B/5KhDfFR8DhwkP4gf4Dwhx0C2LK7DWagFIiXT3KUKOLEEWwkQe4BCN3tjN4ctJpOfeJGPsZr5Yo7v+wRBgDEGay1pmh7RxhBJhTYWk86JpWRyd8+vqcfddMr9wwzx4/aW6+vPqCTCaolVCelQ3GpSoxxW/5GJSniIE4JEEShNnEjCKGQSxMzCiFkUI0J/xmx6j/8wJV1nrovZ9A4lI4ySrpAr/vySpdUsXpJqFi5GMTcKsVwu3GjL5ZIe6PsdWmuklGw2G+q6Yrfbsd/vX3GwtW3Ldrvl8fGRTZ4jqqqk7TrnqMqSpmmcfirruqYsyyPD88F/iCmfnhB5lqGUwvM8dxlZltF1nUs4MCQMHazXa8dqtaIoCmc/xjUNT0WBGBxVVbnxhqRB7547HuRLve/7Iy/tBzlMKJq65vQM3+ZftlNO49umQVxdfeLs7IyLiwvOz8/5MPrI3+Xedtq2QdzcfOfy8pLxeMxoNOLL12+u/d2ufzXi/xhuva4qRLHN6duarqnY9y1NWRx/6PdgnxdAKBkio4A49IgDjzj00TJ6M24BkhirpFsE4SobzSbPyLP1u9nmGYt5ikokqTX8Bo+/zoXKv5lQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf1b4b7e36746fc01de6465370a1cf30/e4706/Cloud_Create_User08.avif 230w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/d1af7/Cloud_Create_User08.avif 460w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/7f308/Cloud_Create_User08.avif 920w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/e1c99/Cloud_Create_User08.avif 1380w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/76ea5/Cloud_Create_User08.avif 1840w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/e1342/Cloud_Create_User08.avif 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf1b4b7e36746fc01de6465370a1cf30/a0b58/Cloud_Create_User08.webp 230w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/bc10c/Cloud_Create_User08.webp 460w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/966d8/Cloud_Create_User08.webp 920w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/445df/Cloud_Create_User08.webp 1380w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/78de1/Cloud_Create_User08.webp 1840w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/e783c/Cloud_Create_User08.webp 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf1b4b7e36746fc01de6465370a1cf30/81c8e/Cloud_Create_User08.png 230w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/08a84/Cloud_Create_User08.png 460w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/c0255/Cloud_Create_User08.png 920w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/b1001/Cloud_Create_User08.png 1380w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/161ec/Cloud_Create_User08.png 1840w", "/en/static/cf1b4b7e36746fc01de6465370a1cf30/00fb1/Cloud_Create_User08.png 2762w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf1b4b7e36746fc01de6465370a1cf30/c0255/Cloud_Create_User08.png",
              "alt": "Create a User in the Cloud",
              "title": "Create a User in the Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      